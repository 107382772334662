<template>
    <div style="display: flex; justify-content: center;  align-items: center; ">
        <div id="barUpload" style="display: flex;  padding-left: 10px !important; padding-right: 10px !important;">

            <div class="barUploadItem" @click="openItemChoosed('1')"
                :style="selectedItem === 'page1' ? `width: ${itemWidth1}%; background-color: #0d2c6d;` : `width:${itemWidth1}%;`">
                <span
                    :style="selectedItem === 'page1' ? 'font-size: 20px; font-weight: 500; color: white;' : 'font-size: 20px; font-weight: 500; color: #0d2c6d;'">
                    ניהול הקלטת תפריט
                </span>
            </div>
            <div class="barUploadItem" @click="openItemChoosed('2')"
                :style="selectedItem === 'page2' ? `width: ${itemWidth2}%; background-color: #0d2c6d;` : `width: ${itemWidth2}%;`">
                <span
                    :style="selectedItem === 'page2' ? 'font-size: 20px; font-weight: 500; color: white;' : 'font-size: 20px; font-weight: 500; color: #0d2c6d;'">
                    ניהול שלוחת הודעות
                </span>
            </div>

            <div class="barUploadItem" @click="openItemChoosed('3')"
                :style="selectedItem === 'page3' ? `width: ${itemWidth2}%; background-color: #0d2c6d;` : `width: ${itemWidth2}%;`">
                <span
                    :style="selectedItem === 'page3' ? 'font-size: 20px; font-weight: 500; color: white;' : 'font-size: 20px; font-weight: 500; color: #0d2c6d;'">
                    קבצים שנמחקו
                </span>
            </div>

            <div class="barUploadItem" @click="openItemChoosed('4')"
                :style="selectedItem === 'page4' ? `width: ${itemWidth2}%; background-color: #0d2c6d;` : `width: ${itemWidth2}%;`">
                <span
                    :style="selectedItem === 'page4' ? 'font-size: 20px; font-weight: 500; color: white;' : 'font-size: 20px; font-weight: 500; color: #0d2c6d;'">
                    קבצים זמניים
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        routerSelected: String
    },
    data: () => ({
        selectedItem: "page1", // This will store the ID of the selected item
        itemWidth1: "20",
        itemWidth2: "15",
    }),
    methods: {
        openItemChoosed(itemNum) {
            if (this.$route.params.page !== `page${itemNum}`) {
                this.$router.replace({ name: `upload`, params: { page: `page${itemNum}` } });
                this.selectedItem = `page${itemNum}`;
                this.$emit('barUploadItemSelected', "page" + itemNum)
            }
        }
    },
    mounted() {
        // Check if the window width is less than or equal to 600px
        if (window.innerWidth <= 600) {
            this.itemWidth1 = "33";
            this.itemWidth2 = "33";
        } else {
            this.itemWidth1 = "20";
            this.itemWidth2 = "15";
        }
    },
    created() {
        this.selectedItem = this.routerSelected;

    },
}
</script>
<style scoped>
#barUpload {

    width: 95%;
    /* background-color: #adacaa2c; */
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
    height: 70px;
    margin-bottom: 50px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Add rounded corners */
    border-radius: 8px;
}

.barUploadItem {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid
}

.barUploadItem:hover {
    background: #0d2c6d;
    cursor: pointer;
}

.barUploadItem:hover span {
    color: white !important;
}
</style>

 