<template>
  <v-dialog v-model="openMode" max-width="600px">
    
    <v-card style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%); max-height: 600px;">
      
    
      <v-data-table id="table1" :headers="headers" :items="listeningArray" sort-by="start" fixed-header mobile-breakpoint="100"
        :footer-props="{ 'items-per-page-options': [500], 'items-per-page-text': 'שורות בעמוד:' }">

        <template  v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }">
          <span class="table-headers-span">{{ header.text }}</span>
        </template>

        <template  #item="{ item, index }">
          <tr class="table-rows">
            <td class="table-cells">{{ index + 1 }}</td>
            <td class="table-cells" style="font-weight: 500 !important;">{{ item.name}}</td>
            <td class="table-cells">{{ item.phone}}</td>
            <td class="table-cells">{{ item.date_listened }}</td>
            <td class="table-cells">{{ item.time_listened }}</td>
            <td class="table-cells">{{ item.precentages }}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <div class="ma-5">
            <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אף אחד לא האזין עדיין להודעה</span>
          </div>
        </template>

      </v-data-table>

    </v-card>
  </v-dialog>
</template>
  
<script>
import Utils from '@/util/utils'

export default {
  props: {
    listeningDetails: Array,
    recordDuration: String,
    value: { type: Boolean, default: false },

  },
  components: {
  },
  data: () => ({
    listeningArray: [],
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'שם', value: "name"},
      { text: 'טלפון', align: 'start', value: 'phone' },
      { text: 'תאריך', value: 'date_Listened' },
      { text: 'זמן שמיעה', value: 'time_listened' },
      { text: 'אחוז שמיעה', value: 'precentages' },
    ],
  }),

  methods: {
    actionConfirmed() {
      this.deleteItem();
    },
    closeDelete() {
      this.openMode = false;
    },
    formatDetails() {
            this.listeningArray = this.listeningArray.map((listener) => {
                return {
                    ...listener,
                    time_listened: this.formatDuration(listener.time_listened),
                    precentages: Utils.getPercentage(listener.time_listened, this.recordDuration),
                }
            });
        },
        formatDuration(seconds) {
          console.log(seconds);
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;

            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = sec.toString().padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
      
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  created() {
    this.listeningArray = [...this.listeningDetails];
     this.formatDetails();
  },

}

</script>
<style scoped>

#table1.v-data-table .v-data-table-header th {
  background-color: rgba(0, 0, 0, 0.116) !important;
  height: 10px !important;
  text-align: center !important;
  border-left: 1px solid white !important;
}

.table-headers-span {
  font-family: 'Heebo' !important;
  font-size: 15px;
  font-weight: 600 !important;
  color: #0d2c6d;
}

.table-rows {
  height: 10px !important;
  box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
  border-radius: 3px !important;
}

.table-cells {
  font-family: 'Heebo' !important;
  font-size: 14px !important;
  color: #47484b;
  border-left: 1px solid white !important;
  text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.192);
  /* Replace with your desired color */
}


</style>
    