<template>
  <div id="mainContainer">
    <v-row no-gutters>
      <SideMenu @isExtendedFunc="isExtendedFunc()"/>
      <div id="systembody" class="scrollbar-style" :style="{ width: isExtended ? $vuetify.breakpoint.smAndUp ? '81.5%' : '95%' : $vuetify.breakpoint.smAndUp ? '97%' : '90%' , right: isExtended ?  $vuetify.breakpoint.smAndUp ? '18.5%' : '10%' : $vuetify.breakpoint.smAndUp ? '3%' : '10%'}">

        <TopBar />

        <div style="margin-top: 10px;">
          <div class="sectionLargeIcon largeServerIcon"></div>
          <div class="widget clearfix">
            <span class="title" style="margin-right: 15px; font-size: 22px; font-weight: 600 !important; color: #183677;" >תשלום</span>
            <div style="margin-top: 30px; display: flex; height: 500px; justify-content: center; align-items: center;">
  
              <div class="box" style="border: 1px solid #eee; padding: 30px; background-color: #F3F6F9;">
                <span style="color: rgb(209, 5, 5); font-size: xx-large;">התשלום נכשל!</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import Auth from '@/services/auth.service.js'
import SideMenu from '@/components/widgets/sideMenu.vue'
import TopBar from '@/components/widgets/TopBar'

export default {
  name: 'upload',
  components: {
    SideMenu,
    TopBar,
  },
  data: () => ({
    isExtended: true,
  }),
  methods: {
    isExtendedFunc(){
    this.isExtended = !this.isExtended;
  }
  },
  created() {
    document.title = 'תשלום בוצע בהצלחה | clickcall'
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style scoped>
#mainContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}

div#systembody {
  position: absolute;
  top: 0;
  height: 100%;
  overflow: auto;
  min-height: 100%;
  background: #fbfbfb;
}

.box{
box-shadow: -1px 3px 5px 1px rgb(240 240 240);
-webkit-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
-moz-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
border-radius: 5px;
}
/* costume scroolbar body */
/* .scrollbar-style::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
width: 10px;
background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
height: 0;
}

:not(.scrollbar-style)::-webkit-scrollbar {
  width: 0px !important;
} */

</style>