<template>
    <v-dialog v-model="openMode" max-width="400">
      <v-card>
        <v-card-title class="headline">המערכת נוצרה בהצלחה!</v-card-title>
        <v-card-text style="font-weight: bold;">{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'SuccessDialog',
    props: {
      message: {
        type: String,
        default: 'פרטי המערכת נשלחו למייל'
      },
      value: { type: Boolean, default: false },
    },
    data() {
      return {
      };
    },
    methods: {
      closeDialog() {
        this.$emit('onClose')
      }
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        }
    },
  };
  </script>
  
  <style scoped>
  </style>
  