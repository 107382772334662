class ApiServices {
    constructor() {
    }

    static requestOptions(methods, body, token) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + token);

        let requestOpt;
        if (methods === "GET") {
            requestOpt = {
                method: methods,
                headers: myHeaders,
            }
        }else{
            requestOpt = {
                method: methods,
                body: body,
                headers: myHeaders,
                redirect: 'follow'
            }
        }
       
        return requestOpt;
    }



}

export default ApiServices;