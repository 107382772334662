<template >
  <v-dialog v-model="openMode" max-width="500px" class="scrollbar-style" style="overflow: auto: !important; min-height: 100%;">

    <v-card   style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%); max-height: 600px; overflow: scroll: !important;">
      
    
      <v-data-table id="table1" :headers="headers" :items="callDetailCloned" sort-by="start" fixed-header mobile-breakpoint="100"
        :footer-props="{ 'items-per-page-options': [500], 'items-per-page-text': 'שורות בעמוד:' }">

        <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }">
          <span class="table-headers-span">{{ header.text }}</span>
        </template>

        <template  #item="{ item, index }">
          <tr class="table-rows">
            <td class="table-cells">{{ index + 1 }}</td>
            <td class="table-cells">{{ item.date_enter }}</td>
            <td class="table-cells">{{ item.context }}</td>
            <td class="table-cells">{{ item.duration }}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-row v-if="progressShow" class="my-5">
            <v-col class="d-flex justify-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
          </v-row>
          <div class="ma-5">
            <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
              כניסות לשלוחה</span>
          </div>
        </template>

      </v-data-table>

    </v-card>
  </v-dialog>
</template>
  
<script>
export default {
  props: {
    callDetails: Array,
    value: { type: Boolean, default: false },

  },
  components: {
  },
  data: () => ({
    callDetailCloned: [],
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'תאריך', align: 'start', value: 'date_enter' },
      { text: 'נכנס לשלוחה', value: 'context' },
      { text: 'זמן שהייה בשלוחה', value: 'duration' },
    ],
  }),

  methods: {
    actionConfirmed() {
      this.deleteItem();
    },
    closeDelete() {
      this.openMode = false;
    },
    formatDetails() {
            this.callDetailCloned = this.callDetailCloned.map((call) => {
                return {
                    ...call,
                    duration: this.formatDuration(call.duration),
                }
            });
        },

        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;

            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = sec.toString().padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  created() {
    this.callDetailCloned = [...this.callDetails];
     this.formatDetails();
  },

}

</script>
<style scoped>

#table1.v-data-table .v-data-table-header th {
  background-color: rgba(0, 0, 0, 0.116) !important;
  height: 10px !important;
  text-align: center !important;
  border-left: 1px solid white !important;
}

.table-headers-span {
  font-family: 'Heebo' !important;
  font-size: 15px;
  font-weight: 600 !important;
  color: #0d2c6d;
}

.table-rows {
  height: 10px !important;
  box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
  border-radius: 3px !important;
}

.table-cells {
  font-family: 'Heebo' !important;
  font-size: 14px !important;
  color: #47484b;
  border-left: 1px solid white !important;
  text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.192);
  /* Replace with your desired color */
}


.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px !important;
  background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}



</style>
    