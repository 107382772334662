<template >
  <div>
    <div id="loginback">
      <div id="login">
        <form @submit.prevent="register">
          <input id="name" name="name" type="text" v-model="userDetails.name" value="" size="40" class="login_name" aria-required="true"
            aria-invalid="false" placeholder="שם איש קשר" required>
          <input id="business_name" name="business_name" v-model="userDetails.business_name" type="text" value="" size="40" class="login_name"
            aria-required="true" aria-invalid="false" placeholder="שם מוסד" required>
          <input id="email" name="email" type="email" v-model="userDetails.email" value="" size="40" class="login_name" aria-required="true"
            aria-invalid="false" placeholder="אימייל" required>
          <input id="phone" name="phone" type="number" v-model="userDetails.phone" value="" size="40" class="login_name" aria-required="true"
            aria-invalid="false" placeholder="טלפון" required>
          <input id="business_id" name="business_id" v-model="userDetails.business_id" type="number" value="" size="40" class="login_name"
            aria-required="true" aria-invalid="false" placeholder="ת.ז" required>
          <div>
            <input type="checkbox" id="termsCheckbox" class="terms-checkbox" v-model="termsAccepted">
            <label for="termsCheckbox" class="Back">
              קראתי ואישרתי את
              <a  class="Back" href="https://dialer.clickcall.co.il/call-center/Terms.pdf" target="_blank"
               style="cursor: pointer; font-weight: bold; text-decoration: underline;">תנאי
                השימוש</a>
            </label>
          </div>
          <input type="submit" value="צור חשבון" class="login_submit" :disabled="overlay"><br>
        </form>
        <div style="display: flex; justify-content: space-between; width: 95%;">
          <div><span class="Back">יש לך כבר חשבון? <span @click="openLogin"
                style="cursor: pointer; font-weight: bold; text-decoration: underline;">התחבר</span></span></div>
        </div>
        <input id="submitme" name="submitme" value="1" type="hidden">

      </div>
    </div>

    <div class="centered-text">
      <div id="foter">
        <span style="color: white; font-weight: bold; font-size: 40px; margin-left: 20px;">מערכת ניהול</span>
        <span style="color: #ffb91a; font-weight: bold; font-size: 40px; margin-left: 20px; display: block;">מה נשמע
          פון</span>
      </div>
    </div>

    <template>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
      </v-overlay>
    </template>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    <SuccessDialog v-if="successDialog" v-model="successDialog" @onClose="onSuccessDialogClose" ></SuccessDialog>
  </div>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import SuccessDialog from '@/components/auth/SuccessDialog.vue'

export default {

  components: {
    SnackBar,
    SuccessDialog
  },

  data: () => ({
    userName: "",
    password: "",
    termsAccepted: false,
    successDialog: false,
    snackbar: false,
    userDetails: {
      name: "",
      business_name: "",
      phone: "",
      email: "", phone: "",
      business_id: "",
      recaptchaToken:""
    },
    snackbarColorBt: "green",
    snacbarText: "",
    overlay: false,
  }),
  methods: {

    async register() {

      // Wait until reCAPTCHA has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "register".
      this.userDetails.recaptchaToken = await this.$recaptcha('register');
      if (!this.userDetails.recaptchaToken) {
        this.showSnackBar("CAPTCHA validation failed", "red");
        return;
      }

      if (!this.termsAccepted) {
        this.showSnackBar("אנא אשר את תנאי השימוש", "red");
        return;
      }

      this.overlay = true;
      let api = process.env.VUE_APP_BASE_URL + "/auth/register";

      try {
        const response = await fetch(api, ApiServices.requestOptions("POST", JSON.stringify(this.userDetails), ""));
        this.overlay = false;
        const jsonObject = await response.json();
        if (response.status >= 400) {
          this.showSnackBar("שגיאת התחברות: " + jsonObject, "red");
        } else if (response.status === 200) {
          this.successDialog = true
        } else {
          this.showSnackBar("שגיאת שרת", "red");
        }
      } catch (error) {
        this.overlay = false;
        this.showSnackBar("Error Registering: " + error, "red");
      }
    },
    openLogin() {
      console.log("openLogin");
      this.$emit("openLogin");
    },
    onSuccessDialogClose() {
      this.successDialog = false;
      this.openLogin();
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    handleResize() {
      // Check if the window width is less than or equal to 600px
      if (window.innerWidth <= 500) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },

  },
  watch: {
    $route() {
      document.title = 'Login | CLICK-CALL'
    },
  },
  mounted() {
    document.title = 'Login | CLICK-CALL'
    if (window.innerWidth <= 500) {
      this.isResponsive = true; // Set to true if screen size is small
    }
    window.addEventListener('resize', this.handleResize);

    this.captchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
  },
  beforeDestroy() {
    // Remove event listener before component is destroyed
    window.removeEventListener('resize', this.handleResize);

  },
}
</script>
<style scoped>
#login {
  width: 65%;
  margin: 4% auto;
  min-height: 40%;
}

div#loginback {
  background: #0d2c6d;
  min-height: 55%;
  position: relative;
  z-index: 99;
}

.login_name {
  font-family: heebo, arial !important;
  border: 0 none !important;
  text-align: right !important;
  padding: 2% 13% 2% 5% !important;
  font-size: 1em !important;
  width: 95% !important;
  margin-bottom: 3% !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: 100% -.9vh !important;
  border-radius: .4vw !important;
  background-color: white;
  background-image: url(@/assets/login/icon_u.png);
}

.login_password {
  font-family: heebo, arial;
  border: 0 none;
  text-align: right;
  padding: 3% 13% 3% 5%;
  width: 95%;
  font-size: 1.25em;
  margin-bottom: 6%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100% -.9vh;
  border-radius: .4vw;
  background-color: white;
  background-image: url(@/assets/login/icon_s.png);
}

.login_submit {
  font-family: heebo, arial;
  padding: 2%;
  background: #fdb721;
  width: 95%;
  font-size: 1.57em;
  margin-bottom: 10px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  border-radius: .4vw;
}

.forgot {
  text-align: right;
  direction: rtl;
  float: right;
  color: #fff;
  font-size: 1.11em;
  font-family: heebo, arial;
  margin: 1% 0%;
}

.Back {
  text-align: right;
  direction: rtl;
  color: #fff !important;
  font-size: 1.11em;
  font-family: heebo, arial;
  margin: 1% 0%;
}

#rememberme {
  float: right;
  margin: 2% 2% 2%;
  /* height: 1.5vh; */
  /* width: 1.5vh; */
  /* border-radius: 0; */
  /* border: none; */
}


#foter {
  background-image: url("@/assets/footer.svg");
  background-size: 108% auto;
  background-repeat: no-repeat;
  width: 34%;
  height: 16vh;
  position: absolute;
  bottom: 2%;
  z-index: 0;
}



.centered-text {
  text-align: center;
}


/* end .squaredFour */


@media only screen and (max-width: 998px) and (min-height: 600px) {

  #login {
    width: 85%;
  }

  .squaredFour label {
    width: 3.5vh;
    height: 3.5vh
  }




  #foter {

    width: 100%;

    position: absolute;

    bottom: 5%;
  }

}


.notification {
  display: block;
  padding: 3% 13% 3% 5%;
  width: 76%;
  border-radius: .4vw;
  margin-bottom: 2vh;
}

/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
