<template >
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <div v-on="on" style="cursor: pointer;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" style="color: #0d2c6d;">mdi-calendar</v-icon>
          </template>
          <span>תאריך סיום</span>
        </v-tooltip>
      </div>
    </template>

    <v-datetime-picker label="לחץ לבחירה" v-model="picker"></v-datetime-picker>
    <!-- <v-date-picker v-model="picker" color="#6a6aec" @click="menu = false" /> -->
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    picker: {
      get() {
        return this.value;
      },
      set(val) {
        this.menu = false;
        this.$emit("input", val);
      },
    },
    getCurrentDate() {
      const currentDate = new Date();
      // Extract date components
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hour = String(currentDate.getHours()).padStart(2, '0');
      const minute = String(currentDate.getMinutes()).padStart(2, '0');

      // Format as "YYYY-MM-DD hh:mm"
      return `${year}-${month}-${day} ${hour}:${minute}`;
    }
  },
  created() {
    if (this.value === "") {
       this.picker = this.getCurrentDate
    }
  }
};
</script>
  