import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '../views/PageNotFoundView.vue'
import LoginView from '../views/LoginView.vue'
import UploadView from '../views/UploadView.vue'
import OnLineView from '../views/OnLineView.vue'
import SubscribesListView from '../views/SubscribesListView.vue'
import CallHistoryView from '../views/CallHistoryView.vue'
import AccountView from '../views/AccountView.vue'
import ReportsView from '../views/ReportsView.vue'
import SettingsView from '../views/SettingsView.vue'
import AgentView from '../views/AgentView.vue'
import AdminView from '../views/AdminView.vue'
import RessetPassView from '../views/ResetPasswordView.vue'
import PaySuccessfulView from '../views/PaySuccessfulView.vue'
import PayFailedView from '../views/PayFailedView.vue'
import LogsView from '../views/LogsView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
  },
  {
    path: '/upload/:page',
    name: 'upload',
    component: UploadView,
  },
  {
    path: '/on-line-users',
    name: 'onLineUsers',
    component: OnLineView,
  },
  {
    path: '/subscribes-list',
    name: 'subscribesList',
    component: SubscribesListView,
  },
  {
    path: '/call-history',
    name: 'callHistory',
    component: CallHistoryView,
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
  },
  {
    path: '/reports',
    name: 'reports',
    component: ReportsView,
  },
  {
    path: '/settings/:page',
    name: 'settings',
    component: SettingsView,
  },
  {
    path: '/agent/:page',
    name: 'agent',
    component: AgentView,
  },
  {
    path: '/admin/:page',
    name: 'admin',
    component: AdminView,
  },
  {
    path: '/resset-password/:token',
    name: 'ressetPass',
    component: RessetPassView,
  },

  {
    path: '/pay-success',
    name: 'paySuccess',
    component: PaySuccessfulView,
  },

  {
    path: '/pay-failed',
    name: 'payFailed',
    component: PayFailedView,
  },
  {
    path: '/logs',
    name: 'logs',
    component: LogsView,
  },

  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
