<template >
    <div>
        <v-container>

            <v-card style="background-color: white;  ">
                <div style="display: flex; padding: 15px; margin-bottom: 10px;">
                    <v-text-field hide-details type="date" v-model="dateLogs" outlined dense
                        style=" max-width: 200px; margin-left: 20px;">
                    </v-text-field>
                    <v-select :items="logFiles" v-model="logFileChoosed" hide-details outlined dense label="מחלקה"
                        :item-text="'hebrewName'" :item-value="'name'" style="max-width: 200px; margin-left: 20px;">
                    </v-select>
                    <v-select v-if="logFileChoosed && logFileChoosed === 'web-app'" :items="logWebTypes"
                        v-model="logTypeWebChoosed" hide-details outlined dense label="סוג לוגים"
                        style="max-width: 200px; margin-left: 20px;">
                    </v-select>
                    <v-select v-else-if="logFileChoosed && logFileChoosed === 'asterisk'" :items="logAsteriskTypes"
                        v-model="logTypeAsteriskChoosed" hide-details outlined dense label="סוג לוגים"
                        :item-text="'hebrewName'" :item-value="'name'" style="max-width: 200px; margin-left: 20px;">
                    </v-select>

                    <v-btn color="#0d2c6d" dark class="mb-2" style="max-width: 20px; " @click="getLogs()">
                        אישור
                    </v-btn>

                    <v-btn outlined small class="mb-2 ms-4" style="max-width: 20px; margin-right: 70px !important;"
                        @click="refreshDetails()">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" dark>
                                    mdi-refresh
                                </v-icon>
                            </template>
                            <span>רענן תוצאות</span>
                        </v-tooltip>
                    </v-btn>

                    <v-text-field label="חיפוש" style="max-width: 300px; margin-right: 30px;" hide-details outlined dense
                        v-model="search"></v-text-field>


                </div>
                <v-row v-if="progressShow" class="my-5">
                    <v-col class="d-flex justify-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>
                </v-row>
                <div>
                    <span style="margin-right: 10px;">תוצאות לוגים: {{ filteredLogs.length }}</span>
                </div>

                <div style="overflow-y: auto; height: 75vh;" class="scrollbar-style">
                    <div v-if="logFileChoosed === 'web-app'">
                        <div v-for="log in filteredLogs"
                            style="padding: 10px; border-bottom: 1px solid #ccc; direction: ltr;">
                            {{ log.date }} {{ log.time }}
                            <span :class="getClass(log.level)">
                                [{{ log.level }}]
                            </span>
                            <p class="log-message" v-html="formatLogWebMessage(log.message)"></p>
                        </div>
                    </div>
                    <div v-else-if="logFileChoosed === 'asterisk' && logTypeAsteriskChoosed !== 'costume'">
                        <div v-for="log in filteredLogsAsterisk"
                            style="padding: 10px; border-bottom: 1px solid #ccc; direction: ltr;">
                            <p v-html="formatLogAsteriskMessage(log)"></p>
                        </div>
                    </div>
                    <div v-else-if="logFileChoosed === 'asterisk' && logTypeAsteriskChoosed === 'costume'">
                        <div v-for="log in filteredLogsAsterisk"
                            style="padding: 10px; border-bottom: 1px solid #ccc; direction: ltr;">
                            <p v-html="formatCostumeLogAsteriskMessage(log)"></p>
                        </div>
                    </div>
                </div>

            </v-card>
        </v-container>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>

import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Utils from "@/util/utils";

export default {

    components: {
        SnackBar
    },
    data: () => ({
        overlay: false,
        editPlanDialog: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        progressShow: false,
        logs: "",
        dateLogs: "",
        logFileChoosed: "asterisk",
        logTypeWebChoosed: "הכל",
        logTypeAsteriskChoosed: "costume",
        logFiles: [{ name: "asterisk", hebrewName: "אסטריסק" }, { name: "web-app", hebrewName: "אתר הניהול" }],
        logWebTypes: ["הכל", "שגיאות", "מידע"],
        logAsteriskTypes: [{ name: "costume", hebrewName: "לוגים מותאם אישית" }, { name: "details", hebrewName: "מידע כללי" }, { name: "debug", hebrewName: "דיבאג" }, { name: "security", hebrewName: "אבטחה" }],
        search: "",
        colorCache: {},
        usedColors: [],
        maxColorCache: 10
    }),
    computed: {
        filteredLogs() {
            let filtered = this.logs;

            if (this.filterLogs === "שגיאות") {
                filtered = filtered.filter(
                    (log) => log.level === "error:" || log.level === "exception:"
                );
            } else if (this.filterLogs === "מידע") {
                filtered = filtered.filter(
                    (log) => log.level !== "error:" && log.level !== "exception:"
                );
            }

            if (this.search) {
                return filtered.filter(item => {
                    return Object.values(item).some(value => value && value.toString().toLowerCase().includes(this.search.toLowerCase()));
                });
            }
            return filtered;
        },
        filteredLogsAsterisk() {
            let filtered = this.logs;

            if (this.search) {
                return filtered.filter(item => {
                    return item.toLowerCase().includes(this.search.toLowerCase());
                });
            }
            return filtered;
        },
    },
    methods: {
        async getLogs() {
            try {
                this.logs = [];
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/logs/get_today_logs?date=${this.dateLogs}&fileType=${this.logFileChoosed}&logType=${this.logTypeAsteriskChoosed}`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    if (jsonObject) {
                        if (this.logFileChoosed === "asterisk") {
                            this.formatLogsAsteriskData(jsonObject);
                        } else {
                            this.formatLogsWebData(jsonObject);
                        }
                    }
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get logs: " + error, "red");
            }
        },
        formatLogsWebData(data) {
            console.log(1111);
            this.logs = data.split('\n').map(line => {
                const parts = line.split(' ');
                return {
                    date: parts[0],
                    time: parts[1],
                    level: parts[2],
                    message: parts.slice(3).join(' ')
                };
            });

        },
        formatLogsAsteriskData(data) {
            console.log(222222);
            this.logs = data.split('\n')
            console.log(this.logs);
        },
        formatLogWebMessage(message) {
            // Regular expression to match specific keywords
            const keywords = /\b(SessionId|DataName|TableName|By|Message|Item|Error)\b/g;
            return message.replace(keywords, '<strong>$1</strong>');
        },
        formatLogAsteriskMessage(message) {
            const keywords = /\b(VERBOSE|Caller|Answer|NoOp|Goto|Set|AGI|Playing|GotoIf|DTMF|IVR)\b/g;
            const callPattern = /\[C-0000[^\]]*\]/g;

            // Replace keywords with bold
            message = message.replace(keywords, '<strong>$1</strong>');

            // Replace [C-0000...till next ] with consistently colored version
            message = message.replace(callPattern, (match) => {
                if (!this.colorCache[match]) {
                    if (this.usedColors.length >= this.maxColorCache) {
                        this.usedColors.shift(); // Remove the oldest color to make space for a new one
                    }
                    const newColor = this.getRandomColor();
                    this.colorCache[match] = newColor;
                    this.usedColors.push(newColor);
                }
                return `<span style="color:${this.colorCache[match]};">${match}</span>`;
            });

            return message;
        },
        formatCostumeLogAsteriskMessage(message) {
            const keywords = /\b(INFO|ERROR|EXCEPTION|CONTROL_STREAM_FILE|STREAM_FILE|PLAYBACK|READ_DIGITS|ENTER|DTMF|HANGUP|IVR|API|LINE_NUMBER|CALLER_ID|CONTEXT|Message)\b/g;
            const channelIdPattern = /CHANNEL_ID: [^,]+/;

            // Replace keywords with bold, and color ERROR or EXCEPTION red
            message = message.replace(keywords, (match) => {
                if (match === 'ERROR' || match === 'EXCEPTION') {
                    return `<strong style="color: red;">${match}</strong>`;
                } else {
                    return `<strong>${match}</strong>`;
                }
            });

            // set random color each channel_id and dont repeat same color until 10 diffrents chaneels
            message = message.replace(channelIdPattern, (match) => {
                if (!this.colorCache[match]) {
                    if (this.usedColors.length >= this.maxColorCache) {
                        this.usedColors.shift(); // Remove the oldest color to make space for a new one
                    }
                    const newColor = this.getRandomColor();
                    this.colorCache[match] = newColor;
                    this.usedColors.push(newColor);
                }
                const channelValue = match.replace("CHANNEL_ID: ", "");
                const channelTitle = "CHANNEL_ID: ";
                console.log(channelValue);
                return `<span style="font-weight: bold;">${channelTitle}</span><span style="color:${this.colorCache[match]}; font-weight: bold;">${channelValue}</span>`;
            });

            return message;
        },
        getRandomColor() {
            // Function to generate a random color component value
            // This function generates values in a more controlled range to avoid very bright colors.
            const randomComponent = () => {
                // Generate a value between 32 and 192, avoiding very bright components
                return Math.floor(Math.random() * (192 - 32) + 32).toString(16).padStart(2, '0');
            };

            let color = `#${randomComponent()}${randomComponent()}${randomComponent()}`;
            while (this.usedColors.includes(color)) {
                color = `#${randomComponent()}${randomComponent()}${randomComponent()}`;
            }
            return color;
        },
        getClass(level) {
            return level === 'info:' ? 'level-info' : 'level-error';
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        setDateLogsToCurrentDate() {
            const today = new Date();
            const formattedDate = today.toISOString().substr(0, 10);
            this.dateLogs = formattedDate;
        },
        refreshDetails() {
            this.getLogs();
        }
    },
    created() {
        this.setDateLogsToCurrentDate();
    },
}
</script>
<style >
.level-info {
    color: rgb(24, 197, 24);
}

.level-error {
    color: red;
}

.scrollbar-style::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
    background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
    width: 10px;
    background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
    height: 0;
}

/* the default page scrollbar will be unvizible */
:not(.scrollbar-style)::-webkit-scrollbar {
    width: 0px !important;
}
</style>
    