<template >
  <div>
    <div class="sideNav" v-if="isExpended"
      :style="{ width: $vuetify.breakpoint.smAndUp ? isExpended ? '18.5%' : '5%' : isExpended ? '80%' : '18.5%' }">
      <!-- :style="{ width: isExpended ? '80%' : '18.5%' }"> -->

      <div style="display: flex; justify-content: space-between; padding: 7px;">
        <div id="manu_img" @click="logOut()">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <img v-on="on" style="cursor: pointer;" src="@/assets/sideNav/icon_logout.svg">
            </template>
            <span>התנתק</span>
          </v-tooltip>
        </div>

        <div v-if="isAdminSwitched">
          <v-tooltip bottom class="logout-button-container">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" dark @click="swichToAdmin()" medium color="#ffb91a">
                mdi-swap-horizontal
              </v-icon>
            </template>
            <span>חזור לניהול</span>
          </v-tooltip>
        </div>

        <div v-if="isAgentSwitched">
          <v-tooltip bottom class="logout-button-container">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" dark @click="swichToAgent()" medium color="#ffb91a">
                mdi-swap-horizontal
              </v-icon>
            </template>
            <span>חזור לניהול סוכן</span>
          </v-tooltip>
        </div>

        <div id="manu_img" @click="expenedHandle()">
          <img src="@/assets/sideNav/icon_expend.svg">
        </div>
      </div>

      <div onclick="manuoc()" id="manub" style="display: none;">
        <a href="#">
          <img src="https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/manu.png" width="350" height="75"
            style="
      width: 2vw;
      height: auto;
      float: left;
      border: 0;
      padding-top: 1vh;
      padding-right: .5vh;
     
      right: 15.5%;
  "> </a>
      </div>

      <div id="logo" style="text-align: center;"><a href="#">
          <img src="https://cpanel.clickcall.co.il/serverscall/sys_icon/logo.png" width="350" height="75"> </a></div>
      <div id="unlogo">
        <img src="@/assets/sideNav/second_menu_logo.svg" width="350" height="71">
        <div class="centered-text">
          <span>מערכת ניהול</span>
          <span style="color: #ffb91a; font-weight: bold;">מה נשמע פון</span>
        </div>
      </div>
      <!-- <div id="Search">

        <input name="filterText" id="filterText" value="" type="text" onkeyup="reloadTable(); return false;"
          placeholder="חיפוש     " style="color: rgb(169, 169, 169);">
      </div> -->
      <div style="border-bottom: 1px solid #2e4a75;"></div>

      <div id="menu_ul" class="scrollbar-stylel"
        style="overflow: scroll;resize: vertical;direction: ltr; border-bottom: 1px solid #0d2c6d; margin: 0px;">
        <ul id="menu">

          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img
                  src="https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_1.png">
              </div>
              <div id="manu_link"><a @click="homeRoute()">דף
                  הבית</a></div>
            </div>
          </li>
          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img src="@/assets/sideNav/icon_account.svg">
              </div>
              <div id="manu_link" @click="accountRoute()"><a>אזור
                  אישי</a></div>
            </div>
          </li>

          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img
                  src="https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_4.png">
              </div>
              <div id="manu_link" @click="onLineuSERSRoute()"><a>שיחות און ליין</a>
              </div>
            </div>
          </li>
          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img
                  src="https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_3.png">
              </div>
              <div id="manu_link" @click="reportsRoute()"><a>דוח
                  שיחות</a></div>
            </div>
          </li>
          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img src="@/assets/sideNav/icon_tips.svg">
              </div>
              <div id="manu_link" @click="callHistoryRoute()"><a>היסטוריית
                  מידע ושימושים</a></div>
            </div>
          </li>

          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img src="@/assets/sideNav/icon_upload.svg">
              </div>
              <div id="manu_link" @click="uploadRoute()"><a>העלאת
                  קבצים</a>
              </div>
            </div>
          </li>


          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img src="@/assets/sideNav/icon_tfutza.svg">
              </div>
              <div id="manu_link" @click="subscribesListRoute()"><a>רשימת
                  תפוצה</a></div>
            </div>
          </li>

          <li class="menu_li">
            <div id="manu_tab">
              <div id="manu_img"><img
                  src="https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_7.png">
              </div>
              <div id="manu_link" @click="settingsRoute()"><a>הגדרות</a>
              </div>
            </div>
          </li>

          <li class="menu_li" v-if="role === 'agent'">
            <div id="manu_tab">
              <div id="manu_img"><img src="@/assets/sideNav/icon_users_panel.svg"></div>
              <div id="manu_link" @click="agentRoute()"><a>פאנל ניהול סוכן</a></div>
            </div>
          </li>

          <li class="menu_li" v-if="role === 'admin'">
            <div id="manu_tab">
              <div id="manu_img"><img src="@/assets/sideNav/icon_users_panel.svg"></div>
              <div id="manu_link" @click="adminRoute()"><a>פאנל ניהול כללי</a></div>
            </div>
          </li>

          <!-- <li class="menu_li li_arrow">
            <div id="manu_tab">
              <div id="manu_img"><img
                  src="https://cpanel.clickcall.co.il/serverscall/admin/assets/images/v3/system/icon_11.png">
              </div>
              <div id="manu_link" @click="logOut()"><a>התנתק</a></div>
            </div>
          </li> -->

        </ul>
      </div>
      <div class="manu_f_ch scrollbar-style">
      </div>
    </div>

    <div v-else class="sideNav" :style="{ width: $vuetify.breakpoint.smAndUp ? '3%' : '10%' }">
      <div style=" padding: 3px;">
        <div id="manu_img" @click="expenedHandle()">
          <img src="@/assets/sideNav/icon_expend.svg">
        </div>
      </div>
    </div>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>
    
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    SnackBar
  },
  data: () => ({
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    search: "",
    isExpended: true,
    role: "",
    isAdminSwitched: false,
    isAgentSwitched: false,
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {
    swichToAdmin() {
      localStorage.setItem("token", localStorage.getItem("tokenAdmin"));
      localStorage.removeItem("tokenAdmin");

      if (localStorage.getItem("tokenAgent")) {
        localStorage.removeItem("tokenAgent");
      }
      this.$router.replace({ path: '/admin/page1' })
    },
    swichToAgent() {
      localStorage.setItem("token", localStorage.getItem("tokenAgent"));
      localStorage.removeItem("tokenAgent");
      this.$router.replace({ path: '/agent/page1' })
    },
    uploadRoute() {
      if (this.$route.name !== 'upload') {
        this.$router.push({ path: '/upload/page1' });
      }
    },
    homeRoute() {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' });
      }
    },
    onLineuSERSRoute() {
      if (this.$route.name !== 'onLineUsers') {
        this.$router.push({ name: 'onLineUsers' });
      }
    },
    subscribesListRoute() {
      if (this.$route.name !== 'subscribesList') {
        this.$router.push({ name: 'subscribesList' });
      }
    },
    reportsRoute() {
      if (this.$route.name !== 'reports') {
        this.$router.push({ name: 'reports' });
      }
    },
    callHistoryRoute() {
      if (this.$route.name !== 'callHistory') {
        this.$router.push({ name: 'callHistory' });
      }
    },
    accountRoute() {
      if (this.$route.name !== 'account') {
        this.$router.push({ name: 'account' });
      }
    },
    settingsRoute() {
      if (this.$route.name !== 'settings') {
        this.$router.push({ path: '/settings/page1' });
      }
    },
    agentRoute() {
      if (this.$route.name !== 'agent') {
        this.$router.push({ path: '/agent/page1' });
      }
    },
    adminRoute() {
      if (this.$route.name !== 'admin') {
        this.$router.push({ path: '/admin/page1' });
      }
    },
    logOut() {
      localStorage.removeItem("token");
      this.$router.replace({ name: 'login' });
    },
    expenedHandle() {
      console.log("childExtend");
      this.isExpended = !this.isExpended;
      this.$emit("isExtendedFunc")
    },
  },
  mounted() {
    this.role = Auth.getUserRole();
    if (localStorage.getItem("tokenAdmin")) {
      this.isAdminSwitched = true;
    }
    if (localStorage.getItem("tokenAgent")) {
      this.isAgentSwitched = true;
    }
  },
}
</script>
<style scoped>
.sideNav {
  position: absolute;
  right: 0;
  top: 0;
  float: right;
  height: 100vh;
  color: #fff;
  line-height: 1.4em;
  background: #0d2c6d;
  font-size: 1.55vh;
  overflow: hidden;
  z-index: 10 !important;
  /* Ensure it overlays over #systembody */
}

.sideNavUnExpended {
  position: absolute;
  right: 0;
  top: 0;
  width: 10%;
  float: right;
  height: 100vh;
  color: #fff;
  line-height: 1.4em;
  background: #0d2c6d;
  font-size: 1.55vh;
  overflow: hidden;
  z-index: 10;
  /* Ensure it overlays over #systembody */
}


ul {
  list-style: none;
  padding-right: 0%;
  /* margin-bottom: 10%; */
  /* border-top: 0.1vh solid #5b565d; */
  /* border-bottom: 0.1vh solid #010c1e; */
}

#logo {
  margin-top: 6%;
  margin-bottom: 3%;
}

#logo img {
  width: 70%;
  height: auto;
}

#unlogo img {
  width: 100%;
  height: auto;
}

#unlogo {
  position: relative;
  width: 100%;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* Adjust text color as per requirement */
  text-align: center;
}

.centered-text span {
  display: block;
  font-weight: 500;
  font-size: medium;
}

#Search>#filterText {
  width: 96%;
  border: 0 solid #ccc;
  font-size: 19px;
  background-color: #214883;
  background-image: url("@/assets/sideNav/icon_Search.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  padding: 4%;
  color: #a6bbcc;
  margin: 0 2%;
  font-size: 1.2em;
  background-size: auto 100%;
}

.menu_li {
  cursor: pointer;
  /* background: url(../../../../assets/images/v3/system/chevron_left.png); */
  background-repeat: no-repeat;
  background-position: 1vh;
  background-size: 3.5vh 3.5vh;
  /* border-top: .01vh solid #010c1e; */
  border-bottom: .01vh solid #2e4a75;
  /* filter: invert(100%); */
  padding-top: 2px;
}

.li_arrow {
  background: none;
}

.menu_li a {
  font-size: 1.2em;
  display: block;
  padding: 5%;
  color: #fff;
  text-decoration: none;
}

li img {
  width: 80%;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

::placeholder {
  color: #a6bbcc;
}


div#manu_tab {
  display: table;
  width: 100%;
}

div#manu_img {
  display: table-cell;
  height: 100%;
  margin-bottom: -.85vh;
  vertical-align: middle;
  width: 15%;
  text-align: center;
}

div#manu_link {
  display: table-cell;
  font-family: heebo, arial;
  vertical-align: middle;
}


div#manu_link>a:hover {
  background: #fff;
  color: rgb(13, 44, 109);
  text-decoration: none;
}


div#customFilter input#filterText {
  display: none;
}

div.manu_f_ch {
  max-height: 30%;
  height: 20%;
  overflow: auto;
}


.seemykdk>div>img {
  left: 1px;
  top: 1px;
}



header .right li {
  padding: 0 10px 0 9px;
  float: left;

  height: 20px;
  vertical-align: top;
}

ul#menu {
  direction: rtl;
  margin: 0;
  margin-top: 10px;
  padding-left: 0px !important;
}

.scrollbar-stylel::-webkit-scrollbar-track {
  border: none;
  background-color: #0d2c6d;
}

.scrollbar-stylel::-webkit-scrollbar {
  width: 5px;
  background-color: #0d2c6d;
  height: 1px;
}

.scrollbar-stylel::-webkit-scrollbar-thumb {
  background-color: #0d2c6d;
}

.scrollbar-stylel::-webkit-resizer {
  background-color: #0d2c6d;
}


:not(.scrollbar-style)::-webkit-scrollbar {
  width: 0px !important;
}

/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    