<template>
  <div id="mainContainer">
    <v-row no-gutters>
      <SideMenu @isExtendedFunc="isExtendedFunc()" />
      <div id="systembody" class="scrollbar-style"
        :style="{ width: isExtended ? $vuetify.breakpoint.smAndUp ? '81.5%' : '95%' : $vuetify.breakpoint.smAndUp ? '97%' : '90%', right: isExtended ? $vuetify.breakpoint.smAndUp ? '18.5%' : '10%' : $vuetify.breakpoint.smAndUp ? '3%' : '10%' }">

        <TopBar />

        <div style="margin-right: 15px; margin-top: 10px;">
          <div class="sectionLargeIcon largeServerIcon"></div>
          <div class="widget clearfix">
            <span class="title" style="font-size: 22px; font-weight: 600 !important; color: #183677;">הסטוריית מידע
              ושימושים</span>
            <div class="widget_inside" style="margin-top: 30px;">

              <CallHistoryComp />
            </div>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import CallHistoryComp from '@/components/callHistory/CallHistoryComp'
import Auth from '@/services/auth.service.js'
import SideMenu from '@/components/widgets/sideMenu.vue'
import TopBar from '@/components/widgets/TopBar'

export default {
  name: 'upload',
  components: {
    SideMenu,
    TopBar,
    CallHistoryComp,
  },
  data: () => ({
    isExtended: true,

  }),
  methods: {
    isExtendedFunc() {
      this.isExtended = !this.isExtended;
    }
  },
  created() {
    document.title = 'הסטוריית שיחות | clickcall'
    let auth = Auth.checkAuth();
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style scoped>
#mainContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}

div#systembody {
  position: absolute;
  top: 0;
  height: 100%;
  overflow-y: auto !important;
  min-height: 100%;
  background: #fbfbfb;
}


/* costume scroolbar body */
.scrollbar-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
}

.scrollbar-style::-webkit-scrollbar-thumb {
  background-color: #183677;
}

.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  background-color: #949494;
}

.scrollbar-style::-webkit-scrollbar {
  height: 0;
}

 /* the default page scrollbar will be unvizible */
:not(.scrollbar-style)::-webkit-scrollbar {
    width: 0px !important;
}





  </style>