<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="text-h5">הוספת הקלטה לשלוחת הודעות</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-file-input chips truncate-length="15" hide-details accept="audio/*" label="הקלטה" v-model="newRecord">
                            </v-file-input>
                        </v-col>
                        <v-col cols="12" sm="12" style="padding-top: 0px;">
                            <v-checkbox v-model="sendCalls" hide-details :label="`שלח צינתוק`"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3F51B5" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#3F51B5" text @click="saveNewRecord()">
                    שמור הקלטה
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import axios from 'axios'

export default {
    props: {
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        newRecord: null,
        sendCalls: false,
    }),

    methods: {
        async saveNewRecord() {

            this.progressShow = true;

            try {
                const token = localStorage.getItem('token');
                let api = process.env.VUE_APP_BASE_URL + "/records/add_record";
                const formData = new FormData();
                formData.append('record', this.newRecord);
                formData.append('sendCalls', this.sendCalls);
                const response = await axios.post(api, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'multipart/form-data; charset=utf-8'
                    }
                });
                this.progressShow = false;
                this.$emit("recordAdded", response.data, "ההקלטה נשמרה בהצלחה", "green");
                this.openMode = false;
            } catch (error) {
                console.log(error);
                this.showSnackBar('שגיאה: ' + error.response.data.msg, 'red');
                this.progressShow = false;
            }

        },

        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
<style></style>
  